import { Script } from 'gatsby'
import React, { useEffect, useRef, useState } from 'react'

const ScriptWrapper = ({ children }) => {
  const [isPageLoaded, setIsPageLoaded] = useState(false)
  const isScriptLoaded = useRef(false)

  useEffect(() => {
    if (!isScriptLoaded.current) {
      const onPageLoad = () => {
        setIsPageLoaded(document.readyState === 'complete')
        window.removeEventListener('load', onPageLoad)
      }
      window.addEventListener('load', onPageLoad, false)
    }
  }, [isScriptLoaded.current, isPageLoaded])
  return (
    <>
      {isPageLoaded && !isScriptLoaded.current && (
        <>
          <Script
            id="fairingSnippet"
            src="https://app.fairing.co/js/enquire-labs.js"
            strategy="idle"
          />
          <Script
            id="usntA40start"
            src="https://a40.usablenet.com/pt/c/goodles/cs-start"
            data-color="dark"
            strategy="idle"
          />
          <Script strategy="idle">{`!function (w, d, t) {
            w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
            )ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
            
            ttq.load('${process.env.GATSBY_TIKTOK_PIXEL}');
            ttq.page();
            }(window, document, 'ttq');`}</Script>

          {/* MNTN pixel */}
          <Script strategy="idle">{`(function(){"use strict";var e=null,b="4.0.0",
      n="34297",
      additional="term=value",
      t,r,i;try{t=top.document.referer!==""?encodeURIComponent(top.document.referrer.substring(0,2048)):""}catch(o){t=document.referrer!==null?document.referrer.toString().substring(0,2048):""}try{r=window&&window.top&&document.location&&window.top.location===document.location?document.location:window&&window.top&&window.top.location&&""!==window.top.location?window.top.location:document.location}catch(u){r=document.location}try{i=parent.location.href!==""?encodeURIComponent(parent.location.href.toString().substring(0,2048)):""}catch(a){try{i=r!==null?encodeURIComponent(r.toString().substring(0,2048)):""}catch(f){i=""}}var l,c=document.createElement("script"),h=null,p=document.getElementsByTagName("script"),d=Number(p.length)-1,v=document.getElementsByTagName("script")[d];if(typeof l==="undefined"){l=Math.floor(Math.random()*1e17)}h="dx.mountain.com/spx?"+"dxver="+b+"&shaid="+n+"&tdr="+t+"&plh="+i+"&cb="+l+additional;c.type="text/javascript";c.src=("https:"===document.location.protocol?"https://":"http://")+h;v.parentNode.insertBefore(c,v)})()`}</Script>
          <Script strategy="idle">{`(function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
              })(window, document, "clarity", "script", "fvozv34f4l");`}</Script>
          <Script src="https://cdn.attn.tv/goodles/dtag.js" strategy="idle" />
          <Script src="https://www.dwin1.com/19038.js" strategy="idle" />
          <Script>{(isScriptLoaded.current = true)}</Script>
          <Script>{console.log('Scripts loaded')}</Script>
        </>
      )}
      {children}
    </>
  )
}

export default ScriptWrapper
